import {
  createSystem,
  defaultConfig,
  defineConfig,
  defineRecipe,
  defineSemanticTokens,
  defineSlotRecipe,
  defineTokens,
} from '@chakra-ui/react';
import { fontDefault, fontMonospaced } from '@m/styled/fonts';

import { typographyRecipe, typographyMixins } from './typography';

export const buttonRecipe = defineRecipe({
  base: {
    padding: '16px',
    fontSize: '16px',
  },
  variants: {
    size: {
      sm: {
        padding: '10px',
        fontSize: '14px',
      },
    },
    variant: {
      primary: {
        bg: 'gray.900',
        color: 'gray.000',
        _hover: {
          bg: 'gray.700',
        },
        _active: {
          bg: 'gray.600',
        },
        _disabled: {
          bg: 'gray.100',
          color: 'gray.500',
        },
      },
      secondary: {
        bg: 'gray.200',
        color: 'gray.900',
        _hover: {
          bg: 'gray.300',
        },
        _active: {
          bg: 'gray.400',
        },
        _disabled: {
          bg: 'gray.100',
          color: 'gray.500',
        },
      },
      tertiary: {
        bg: 'gray.000',
        borderColor: 'gray.400',
        color: 'gray.900',
        _hover: {
          bg: 'gray.100',
          borderColor: 'gray.500',
        },
        _active: {
          bg: 'gray.200',
          borderColor: 'gray.600',
        },
        _disabled: {
          bg: 'gray.000',
          color: 'gray.500',
          borderColor: 'gray.200',
        },
      },
      brand: {
        bg: 'brand.300',
        color: 'gray.000',
        _hover: {
          bg: 'brand.400',
        },
        _active: {
          bg: 'brand.500',
        },
        _disabled: {
          bg: 'brand.000',
          color: 'gray.600',
        },
      },
    },
    destructive: {
      true: {},
    },
  },
  compoundVariants: [
    {
      destructive: true,
      variant: 'primary',
      css: {
        bg: 'red.300',
        _hover: {
          bg: 'red.400',
        },
        _active: {
          bg: 'red.500',
        },
        _disabled: {
          bg: 'red.000',
          color: 'gray.600',
        },
      },
    },
    {
      destructive: true,
      variant: 'secondary',
      css: {
        bg: 'gray.000',
        borderColor: 'red.400',
        color: 'red.400',
        _hover: {
          bg: 'red.000',
        },
        _active: {
          bg: 'red.000',
          borderColor: 'red.500',
          color: 'red.400',
        },
        _disabled: {
          bg: 'gray.000',
          borderColor: 'red.100',
          color: 'red.200',
        },
      },
    },
  ],
});

export const inputRecipe = defineRecipe({
  variants: {
    readOnly: {
      true: {
        bg: 'fill.input.read-only',
        color: 'text.read-only',
      },
    },
    disabled: {
      true: {
        borderColor: 'stroke.border.disabled',
        color: 'text.disabled',
      },
    },
  },
});

export const fieldRecipe = defineSlotRecipe({
  slots: ['root', 'label', 'helperText', 'errorText'],
  base: {
    root: {
      gap: 2,
    },
    label: typographyMixins.SmallBody,
    helperText: typographyMixins.ExtraSmallBody,
    errorText: typographyMixins.ExtraSmallBody,
  },
});

const tokens = defineTokens({
  fonts: {
    body: { value: fontDefault },
    heading: { value: fontDefault },
    mono: { value: fontMonospaced },
  },
  radii: {
    sm: { value: '6px' },
  },
  colors: {
    // https://www.figma.com/design/ebpCij6CDTHYedGBKuiwhF/Design-System--%3E-Library-(WIP)?node-id=1-31&m=dev
    gray: {
      '000': { value: '#FFFFFF' },
      100: { value: '#F5F5F5' },
      200: { value: '#F0F0F0' },
      300: { value: '#DFDFDF' },
      400: { value: '#CCCCCC' },
      500: { value: '#9E9E9E' },
      600: { value: '#5E5E5E' },
      700: { value: '#373737' },
      800: { value: '#181818' },
      900: { value: '#000000' },
    },
    red: {
      '000': { value: '#FFF0EE' },
      100: { value: '#FFC6BE' },
      200: { value: '#FF6652' },
      300: { value: '#D4311C' },
      400: { value: '#A51F0F' },
      500: { value: '#891406' },
      600: { value: '#761104' },
    },
    green: {
      '000': { value: '#DFFFEF' },
      100: { value: '#95E8BD' },
      200: { value: '#25BD6F' },
      300: { value: '#0F8F4D' },
      400: { value: '#0E733F' },
      500: { value: '#0D5C33' },
      600: { value: '#064F2A' },
    },
    yellow: {
      200: { value: '#FFE359' },
    },
    brand: {
      '000': { value: '#F7F7FF' },
      100: { value: '#E0DFFF' },
      200: { value: '#ABA8FF' },
      300: { value: '#5F59FF' },
      400: { value: '#4842DF' },
      500: { value: '#2D28A7' },
      600: { value: '#07036C' },
    },
  },
});

const semanticTokens = defineSemanticTokens({
  colors: {
    border: {
      DEFAULT: { value: 'colors.gray.500' },
      error: { value: 'colors.red.300' },
    },
    fg: {
      muted: { value: 'colors.gray.600' },
      error: { value: 'colors.red.300' },
    },
    icon: {
      interactive: { value: 'colors.gray.900' },
      descriptive: { value: 'colors.gray.600' },
      disabled: { value: 'colors.gray.500' },
      white: { value: 'colors.gray.000' },
      error: { value: 'colors.red.300' },
    },
    text: {
      primary: { value: 'colors.gray.900' },
      secondary: { value: 'colors.gray.600' },
      tertiary: { value: 'colors.gray.400' },
      'read-only': { value: 'colors.gray.700' },
      disabled: { value: 'colors.gray.500' },
      white: { value: 'colors.gray.000' },
      error: { value: 'colors.red.300' },
      discount: { value: 'colors.green.300' },
    },
    status: {
      error: { value: 'colors.red.300' },
      success: { value: 'colors.green.300' },
    },
    fill: {
      background: {
        default: { value: 'colors.gray.000' },
        'light-gray': { value: 'colors.gray.100' },
      },
      interactive: {
        enabled: { value: 'colors.gray.000' },
        hover: { value: 'colors.gray.100' },
        selected: { value: 'colors.gray.200' },
        'hover-selected': { value: 'colors.gray.300' },
      },
      input: {
        enabled: { value: 'colors.gray.000' },
        hover: { value: 'colors.gray.100' },
        active: { value: 'colors.gray.000' },
        focused: { value: 'colors.gray.000' },
        'read-only': { value: 'colors.gray.200' },
        disabled: { value: 'colors.gray.000' },
        error: { value: 'colors.gray.000' },
        filled: { value: 'colors.gray.000' },
      },
      button: {
        primary: {
          enabled: { value: 'colors.gray.900' },
          hover: { value: 'colors.gray.800' },
          active: { value: 'colors.gray.700' },
          focused: { value: 'colors.gray.900' },
          selected: { value: 'colors.gray.900' },
          disabled: { value: 'colors.gray.100' },
        },
        secondary: {
          enabled: { value: 'colors.gray.200' },
          hover: { value: 'colors.gray.300' },
          active: { value: 'colors.gray.400' },
          focused: { value: 'colors.gray.200' },
          selected: { value: 'colors.gray.700' },
          disabled: { value: 'colors.gray.100' },
        },
        tertiary: {
          enabled: { value: 'colors.gray.000' },
          hover: { value: 'colors.gray.100' },
          active: { value: 'colors.gray.200' },
          focused: { value: 'colors.gray.000' },
          selected: { value: 'colors.gray.000' },
          disabled: { value: 'colors.gray.000' },
        },
        pay: {
          enabled: { value: 'colors.brand.300' },
          hover: { value: 'colors.brand.400' },
          active: { value: 'colors.brand.500' },
          focused: { value: 'colors.brand.300' },
          selected: { value: 'colors.brand.600' },
          disabled: { value: 'colors.brand.000' },
        },
      },
    },
    stroke: {
      border: {
        default: { value: 'colors.gray.500' },
        disabled: { value: 'colors.gray.300' },
        focused: { value: 'colors.gray.900' },
        'focused-wrapper': { value: 'colors.gray.000' },
        hover: { value: 'colors.gray.600' },
        error: { value: 'colors.red.300' },
        menu: { value: 'colors.gray.300' },
      },
      button: {
        tertiary: {
          enabled: { value: 'colors.gray.400' },
          hover: { value: 'colors.gray.500' },
          active: { value: 'colors.gray.600' },
          focused: { value: 'colors.gray.900' },
          selected: { value: 'colors.gray.900' },
          disabled: { value: 'colors.gray.200' },
        },
      },
      divider: {
        default: { value: 'colors.gray.200' },
      },
    },
  },
});

const customConfig = defineConfig({
  theme: {
    recipes: {
      button: buttonRecipe,
      input: inputRecipe,
      text: typographyRecipe,
    },
    slotRecipes: {
      field: fieldRecipe,
    },
    tokens,
    semanticTokens,
  },
  globalCss: {
    '*::placeholder': {
      color: 'text.tertiary',
    },
    '*::selection': {
      bg: '',
    },
  },
});

export const system = createSystem(defaultConfig, customConfig);
